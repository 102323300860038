import {
  UserOutlined,
  LeftCircleOutlined,
  HddOutlined,
  RocketOutlined,
  SmileOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Image, Layout, Menu, Space } from "antd";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
const { Header, Content, Sider } = Layout;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const items = [
  // getItem(
  //   <Link to="/admin/dashboard">Dashboard</Link>,
  //   "dashboard",
  //   <PieChartOutlined />
  // ),
  // getItem("Master", "master", <HddOutlined />, [
  //   // getItem(
  //   //   <Link to="/admin/master/event">Event</Link>,
  //   //   "event",
  //   //   <DesktopOutlined />
  //   // ),
  //   // getItem(
  //   //   <Link to="/admin/master/guide">Guide</Link>,
  //   //   "guide",
  //   //   <FileDoneOutlined />
  //   // ),
  //   // getItem(
  //   //   <Link to="/admin/master/guestbook">Guestbook</Link>,
  //   //   "guestbook",
  //   //   <TeamOutlined />
  //   // ),
  // ]),
  // getItem(
  //   <Link to="/transaction">Transaction</Link>,
  //   "transaction",
  //   <RocketOutlined />
  // ),

  getItem(
    <Link to="/master/category">Category</Link>,
    "category",
    <HddOutlined />
  ),
  // getItem(
  //   <Link to="/master/client">Client</Link>,
  //   "client",
  //   <UserOutlined />
  // ),
];

function AdminLayout(props) {
  const [collapsed, setCollapsed] = useState(false);
  let navigate = useNavigate();

  const logout = () => {
    localStorage.clear();
    navigate("/login");
  };

  const profileItems = (
    <Menu
      className="profile-content"
      onClick={() => logout()}
      items={[
        {
          key: "2",
          danger: true,
          label: "Logout",
          icon: <LogoutOutlined />,
        },
      ]}
    />
  );

  return (
    <Layout>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        className="admin-sider"
      >
        <div className="logo text-center">
          <Image
            width={collapsed ? "80px" : "150px"}
            src="/assets/images/src.webp"
            className="p-3"
            preview={false}
          />
        </div>
        <Menu
          className="admin-menu"
          defaultSelectedKeys={[window.location.pathname.split("/").pop()]}
          mode="inline"
          items={items}
        />
      </Sider>
      <Layout
        className="site-layout"
        style={{
          marginLeft: collapsed ? 80 : 200,
          transition: "all 0.2s",
        }}
      >
        <Header className="site-layout-background d-flex align-items-end justify-content-end">
          <Dropdown overlay={profileItems} className="profile-content">
            <a className="profile" onClick={(e) => e.preventDefault()}>
              <Space>
                <Image height={50} src="/assets/images/default.png" preview={false} />
                {localStorage.getItem("name")}
              </Space>
            </a>
          </Dropdown>
        </Header>
        <Content className="layout-admin container">
          {props.src && (
            <div className="d-flex justify-content-end w-100 mb-3">
              <Link to={props.src}>
                <Button type="primary" icon={<LeftCircleOutlined />}>
                  Back
                </Button>
              </Link>
            </div>
          )}
          {props.children}
        </Content>
      </Layout>
    </Layout>
  );
}

export default AdminLayout;
