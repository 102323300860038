import { Button, Form, Input, Modal, Radio, notification } from "antd";
import React, { useEffect, useState } from "react";
import Api from "../../../Config/Api";

function AksiInput(props) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    setIsModalVisible(props.modal.open);
    if (
      props.modal.open === true &&
      (props.modal.type === "detail" || props.modal.type === "edit")
    ) {
      Api.get(`api/master/input/${props.modal.id}`)
        .then((res) => {
          form.setFieldsValue({
            name: res.data?.success.name,
            position: res.data?.success.position,
            is_multiple: res.data?.success.is_multiple,
            operator: res.data?.success.operator,
            length: res.data?.success.length,
            // width: res.data?.success.width,
            // high: res.data?.success.high,
            unit: res.data?.success.unit,
            is_required: res.data?.success.is_required,
          });
        })
        .catch(function (e) {
          var message = e.message;
          if (e.response.data.message) {
            message = e.response.data.message;
          }
          notification.open({
            message: "Error",
            type: "error",
            description: message,
          });
        });
    }
  }, [props.modal.open]);

  const handleSubmit = () => {
    let submitData = {
      ...form.getFieldsValue(),
      length: 1,
      categories_id: props.modal.data_id,
    };
    if (props.modal.type === "edit") {
      submitData = {
        ...form.getFieldsValue(),
        _method: "PUT",
        length: 1,
        categories_id: props.modal.data_id,
      };
    }
    Api({
      method:
        props.modal.type === "add" || props.modal.type === "edit"
          ? "post"
          : "delete",
      url: props.modal.id
        ? `api/master/input/${props.modal.id}`
        : `api/master/input`,
      data: submitData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        props.setModal({ open: false, submit: true });
        form.resetFields();
        notification.open({
          message: "Success",
          type: "success",
          description: `Data ${props.modal.type}!`,
        });
      })
      .catch(function (e) {
        var message = e.message;
        if (e.response.data.message) {
          message = e.response.data.message;
        }
        notification.open({
          message: "Error",
          type: "error",
          description: message,
        });
      });
  };

  const handleCancel = () => {
    form.resetFields();
    props.setModal({ open: false });
  };

  const footer = () => {
    const cancel = (
      <Button key="cancel" onClick={handleCancel}>
        Cancel
      </Button>
    );
    const submit = (
      <Button key="submit" type="primary" onClick={form.submit}>
        {props.modal.type === "delete" ? "Delete" : "Submit"}
      </Button>
    );
    if (props.modal.type === "detail") {
      return [cancel];
    } else {
      return [cancel, submit];
    }
  };

  return (
    <Modal
      title={props.modal.label}
      visible={isModalVisible}
      onOk={form.submit}
      okText="Submit"
      onCancel={handleCancel}
      footer={footer()}
    >
      <Form
        name="basic"
        className="form"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        // onFinish={onFinish}
        autoComplete="off"
        form={form}
        onFinish={handleSubmit}
        disabled={props.modal.type === "detail" ? true : false}
      >
        {props.modal.type === "delete" ? (
          <>
            <Form.Item
              name="id"
              className="d-none"
              rules={[
                {
                  required: true,
                  type: "hidden",
                },
              ]}
            >
              <Input />
            </Form.Item>
            Are you sure delete this data?
          </>
        ) : (
          <>
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Position"
              name="position"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input type="number" />
            </Form.Item>
            <Form.Item
              label="Unit"
              name="unit"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Operator"
              name="operator"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Radio.Group>
                <Radio value={"Multiplication"}>Multiplication</Radio>
                <Radio value={"Addition"}>Addition</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="Is Required"
              name="is_required"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Radio.Group>
                <Radio value={0}>No</Radio>
                <Radio value={1}>Yes</Radio>
              </Radio.Group>
            </Form.Item>
            {/* <Form.Item
              label="Width"
              name="width"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Radio.Group>
                <Radio value={0}>No</Radio>
                <Radio value={1}>Yes</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="Height"
              name="high"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Radio.Group>
                <Radio value={0}>No</Radio>
                <Radio value={1}>Yes</Radio>
              </Radio.Group>
            </Form.Item> */}
          </>
        )}
      </Form>
    </Modal>
  );
}

export default AksiInput;
