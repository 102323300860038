import React, { useEffect, useState } from "react";
import { Button, Form, Image, Input, notification } from "antd";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import LoginLayout from "../../Layout/AuthLayout";
import Api from "../../Config/Api";

function LoginPage(props) {
  let navigate = useNavigate();
  const [input, setInput] = useState({ email: "", password: "" });

  useEffect(() => {
    if (localStorage.getItem("is_authenticated")) {
      if (localStorage.getItem("role") === "Admin") {
        navigate("/master/category");
      }
    }
  }, []);

  function handleChange(evt) {
    const value = evt.target.value;
    setInput({
      ...input,
      [evt.target.name]: value,
    });
  }

  function onSubmit() {
    axios
      .post(process.env.REACT_APP_BACKEND_URL + "api/login", input, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const data = res?.data?.success;
        localStorage.setItem("is_authenticated", true);
        localStorage.setItem("expires_in", data.token.expires_in);
        localStorage.setItem("access_token", data.token.access_token);
        localStorage.setItem("refresh_token", data.token.refresh_token);
        localStorage.setItem("id", data.user.id);
        localStorage.setItem("name", data.user.name);
        localStorage.setItem("email", data.user.email);
        localStorage.setItem("role", data.user.role);
        Api.defaults.headers.Authorization = `Bearer ${data.token.access_token}`;
        if (data.user.role === "Admin") {
          navigate("/master/category");
        } else {
          notification.open({
            message: "Error",
            type: "error",
            description: "Anda Tidak Memiliki Akses",
          });
        }
      })
      .catch(function (e) {
        var message = e.message;
        if (e.response.data.message) {
          message = e.response.data.message;
        }
        notification.open({
          message: "Error",
          type: "error",
          description: message,
        });
      });
  }

  return (
    <LoginLayout>
      <div className="layout-login">
        <div className="text-center">
          <div>
            <Image
              className="logo-login"
              src="/assets/images/src.webp"
              preview={false}
            />
          </div>
          <div className="mt-3 fw-bold fs-3">Administrator Login</div>
        </div>
        <Form
          className="form-login mt-4"
          autoComplete="off"
          onFinish={onSubmit}
        >
          <Input
            name="email"
            type="email"
            className="mb-3"
            placeholder="Email"
            value={input.email}
            onChange={handleChange}
            required={true}
          />
          <Input
            name="password"
            type="password"
            placeholder="Password"
            value={input.password}
            onChange={handleChange}
            required={true}
          />
          <div className="text-end my-3">
          </div>
          <Button
            htmlType="submit"
            className="btn bg-warning text-white w-100 h-100 py-2 mt-3"
          >
            LOG IN
          </Button>
        </Form>
      </div>
    </LoginLayout>
  );
}

export default LoginPage;
