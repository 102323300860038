import { Button, Form, Input, Modal, notification, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Colorpicker, ColorPickerValue } from "antd-colorpicker";
import Api from "../../../Config/Api";

function AksiCategory(props) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [color, setColor] = useState("");

  const onChangeColor = (color) => {
    setColor(color?.hex);
  };

  useEffect(() => {
    setIsModalVisible(props.modal.open);
    if (
      props.modal.open === true &&
      (props.modal.type === "detail" || props.modal.type === "edit")
    ) {
      Api.get(`api/master/category/${props.modal.id}`)
        .then((res) => {
          form.setFieldsValue({
            name: res.data?.success.name,
            position: res.data?.success.position,
            color: (res.data?.success.color) ? (res.data?.success.color):"#fff",
          });
          console.log(res.data?.success.color)
          setColor(res.data?.success.color);
        })
        .catch(function (e) {
          var message = e.message;
          if (e.response.data.message) {
            message = e.response.data.message;
          }
          notification.open({
            message: "Error",
            type: "error",
            description: message,
          });
        });
    }
  }, [props.modal.open]);

  const handleSubmit = () => {
    let submitData = form.getFieldsValue();
    if (props.modal.type === "edit") {
      submitData = { ...form.getFieldsValue(), _method: "PUT" };
    }
    submitData.color = color;
    Api({
      method:
        props.modal.type === "add" || props.modal.type === "edit"
          ? "post"
          : "delete",
      url: props.modal.id
        ? `api/master/category/${props.modal.id}`
        : `api/master/category`,
      data: submitData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        props.setModal({ open: false, submit: true });
        form.resetFields();
        notification.open({
          message: "Success",
          type: "success",
          description: `Data ${props.modal.type}!`,
        });
      })
      .catch(function (e) {
        var message = e.message;
        if (e.response.data.message) {
          message = e.response.data.message;
        }
        notification.open({
          message: "Error",
          type: "error",
          description: message,
        });
      });
  };

  const handleUpload = ({ fileList }, type) => {
    form.setFieldsValue({ [type]: fileList[0].originFileObj });
  };

  const handleCancel = () => {
    form.resetFields();
    props.setModal({ open: false });
  };

  const footer = () => {
    const cancel = (
      <Button key="cancel" onClick={handleCancel}>
        Cancel
      </Button>
    );
    const submit = (
      <Button key="submit" type="primary" onClick={form.submit}>
        {props.modal.type === "delete" ? "Delete" : "Submit"}
      </Button>
    );
    if (props.modal.type === "detail") {
      return [cancel];
    } else {
      return [cancel, submit];
    }
  };

  return (
    <Modal
      title={props.modal.label}
      visible={isModalVisible}
      onOk={form.submit}
      okText="Submit"
      onCancel={handleCancel}
      footer={footer()}
    >
      <Form
        name="basic"
        className="form"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        // onFinish={onFinish}
        autoComplete="off"
        form={form}
        onFinish={handleSubmit}
        disabled={props.modal.type === "detail" ? true : false}
      >
        {props.modal.type === "delete" ? (
          <>
            <Form.Item
              name="id"
              className="d-none"
              rules={[
                {
                  required: true,
                  type: "hidden",
                },
              ]}
            >
              <Input />
            </Form.Item>
            Are you sure delete this data?
          </>
        ) : (
          <>
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Position"
              name="position"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input type="number" />
            </Form.Item>
            <Form.Item
              label="Background Color"
              name="color"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Colorpicker popup value={color} onChange={onChangeColor} />
            </Form.Item>
            {/* <Form.Item
              label="Logo"
              name="image"
              // rules={[
              //   {
              //     required: props.modal.type === "add" ? true : false,
              //   },
              // ]}
            >
              <Upload
                name="image"
                listType="picture"
                onChange={(evt) => handleUpload(evt, "image")}
                beforeUpload={() => false}
                maxCount={1}
                accept={".jpeg, .jpg, .png"}
              >
                <Button icon={<UploadOutlined />}>Click to upload</Button>
              </Upload>
            </Form.Item> */}
          </>
        )}
      </Form>
    </Modal>
  );
}

export default AksiCategory;
