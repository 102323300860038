import * as React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import "./App.css";
import MasterCategoryPage from "./Pages/Master/Category/MasterCategoryPage";
import LoginPage from "./Pages/Auth/LoginPage";
import MasterSubCategoryPage from "./Pages/Master/SubCategory/MasterSubCategoryPage";
import MasterItemPage from "./Pages/Master/Item/MasterItemPage";
import MasterInputPage from "./Pages/Master/Input/MasterInputPage";
import MasterLoadingPage from "./Pages/Master/Loading/MasterLoadingPage";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MasterCategoryPage />} />
        <Route path="login" element={<LoginPage />} />
        <Route path="master">
          <Route path="category" element={<MasterCategoryPage />} />
          <Route path="sub-category/:id" element={<MasterSubCategoryPage />} />
          <Route path="item/:id" element={<MasterItemPage />} />
          <Route path="input/:id" element={<MasterInputPage />} />
          <Route path="loading" element={<MasterLoadingPage />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
