import React, { useEffect, useState } from "react";
import { Image, notification, Table, Tag } from "antd";
import AdminLayout from "../../../Layout/AdminLayout";
import PageHeaderComponent from "../../../Components/Admin/Page/PageHeaderComponent";
import ActionComponent from "../../../Components/Admin/Page/ActionComponent";
import Api from "../../../Config/Api";
import AksiItem from "./AksiMasterItem";
import { useNavigate, useParams } from "react-router-dom";

function MasterItemPage(props) {
  const [data, setData] = useState();
  const [dataDetail, setDataDetail] = useState();
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    search: "",
  });
  const [modal, setModal] = useState({
    type: "",
    id: "",
    submit: false,
  });
  let navigate = useNavigate();
  const { id } = useParams();

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: true,
      width: "30%",
    },
    {
      title: "Position",
      dataIndex: "position",
      sorter: true,
      width: "5%",
    },
    {
      title: "Price",
      dataIndex: "price",
      sorter: true,
      width: "20%",
      render: (price) => {
        return new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
        }).format(price);
      },
    },
    // {
    //   title: "Image",
    //   dataIndex: "image",
    //   width: "30%",
    //   align: "center",
    //   render: (image) => {
    //     if (image) {
    //       return (
    //         <Image
    //           style={{ maxHeight: "200px" }}
    //           src={process.env.REACT_APP_BACKEND_URL + "storage/" + image}
    //         />
    //       );
    //     }
    //   },
    // },
    {
      title: "Aksi",
      dataIndex: "id",
      width: "15%",
      align: "center",
      render: (ids) => {
        return <ActionComponent id={ids} data_id={id} setModal={setModal} detail={false} />;
      },
    },
  ];

  const getDataDetail = () => {
    Api.get(`api/master/sub-category/${id}`)
      .then((res) => {
        setDataDetail(res.data?.success);
      })
      .catch(function (e) {
        var message = e.message;
        if (e.response.data.message) {
          message = e.response.data.message;
          if (e.response.status === 401) {
            localStorage.clear();
            navigate("/login");
          }
        }
        notification.open({
          message: "Error",
          type: "error",
          description: message,
        });
      });
  };

  const fetchData = (params = {}) => {
    setLoading(true);
    Api.get(
      `api/master/item?page=${params.pagination.current}&limit=${
        params.pagination.pageSize
      }&sort_by=${params.sortField ? params.sortField : ""}&sort_order=${
        params.sortOrder ? params.sortOrder : ""
      }&search=${params.pagination.search}&id=${id}`
    )
      .then((res) => {
        setData(res.data?.success.data);
        setLoading(false);
        setPagination({
          ...params.pagination,
          total: res.data?.success.total,
        });
      })
      .catch(function (e) {
        setLoading(false);
        var message = e.message;
        if (e.response.data.message) {
          message = e.response.data.message;
          if (e.response.status === 401) {
            localStorage.clear();
            navigate("/login");
          }
        }
        notification.open({
          message: "Error",
          type: "error",
          description: message,
        });
      });
  };

  useEffect(() => {
    fetchData({
      pagination,
    });
  }, [pagination.search]);

  useEffect(() => {
    getDataDetail();
  }, []);

  useEffect(() => {
    if (modal.submit === true) {
      fetchData({
        pagination,
      });
    }
  }, [modal.submit]);

  const handleTableChange = (newPagination, filters, sorter) => {
    fetchData({
      sortField: sorter.field,
      sortOrder: sorter.order === "ascend" ? "asc" : "desc",
      pagination: newPagination,
      ...filters,
    });
  };

  return (
    <AdminLayout>
      <PageHeaderComponent
        pagination={pagination}
        setPagination={setPagination}
        setModal={setModal}
        label={"Data Item " + dataDetail?.name}
        back={"/master/sub-category/" + dataDetail?.categories_id}
        id={id}
      >
        <Table
          bordered
          columns={columns}
          rowKey={(record) => record.id}
          dataSource={data}
          pagination={pagination}
          loading={loading}
          onChange={handleTableChange}
        />
      </PageHeaderComponent>

      <AksiItem modal={modal} setModal={setModal} />
    </AdminLayout>
  );
}

export default MasterItemPage;
