import { Button, Space } from "antd";
import React from "react";
import {
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  Pen,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

function ActionComponent(props) {
  const navigate = useNavigate();
  return (
    <Space size="small">
      {props.detail && !props?.loading && (
        <Button
          className="bg-primary text-white"
          onClick={() => navigate(props?.navigate)}
        >
          {props?.category?"Sub Category":"Item"}
        </Button>
      )}
      {props.input && !props?.loading && (
        <Button
          className="bg-primary text-white"
          onClick={() => navigate(props?.input)}
        >
          Input
        </Button>
      )}
      {props?.loading && (
        <Button
          className="bg-primary text-white"
          onClick={() => navigate("/master/loading")}
        >
          Condition
        </Button>
      )}
      <Button
        className="bg-warning text-white"
        icon={<EditOutlined />}
        onClick={() =>
          props.setModal({
            open: true,
            label: "Edit Data",
            type: "edit",
            id: props.id,
            data_id: props.data_id,
          })
        }
      />
      <Button
        className="bg-danger text-white"
        icon={<DeleteOutlined />}
        onClick={() =>
          props.setModal({
            open: true,
            label: "Delete Data",
            type: "delete",
            id: props.id,
            data_id: props.data_id,
          })
        }
      />
    </Space>
  );
}

export default ActionComponent;
