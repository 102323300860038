import React, { useEffect, useState } from "react";
import { PlusCircleOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Input, PageHeader } from "antd";
import { Link } from "react-router-dom";

function PageHeaderComponent(props) {
  const [data, setData] = useState([]);

  function handleChange(evt) {
    const value = evt.target.value;
    props.setPagination({
      ...props.pagination,
      [evt.target.name]: value,
    });
  }

  useEffect(() => {
    if (props?.back) {
      setData([
        <Link to={props?.back}>
          <Button
            className="bg-warning text-white"
            icon={<ArrowLeftOutlined />}
          >
            Back
          </Button>
        </Link>,
        <Button
          type="primary"
          icon={<PlusCircleOutlined />}
          onClick={() =>
            props.setModal({
              open: true,
              label: "Add " + props.label,
              data_id: props?.id,
              type: "add",
            })
          }
        >
          Add
        </Button>,
      ]);
    } else {
      setData([
        <Button
          type="primary"
          icon={<PlusCircleOutlined />}
          onClick={() =>
            props.setModal({
              open: true,
              label: "Add " + props.label,
              type: "add",
            })
          }
        >
          Add
        </Button>,
      ]);
    }
  }, [props?.back]);

  return (
    <PageHeader title={props.label} className="site-page-header" extra={data}>
      <hr></hr>
      <div className="table-responsive">
        <div className="d-flex align-items-end mb-3 search-box">
          <Input
            placeholder="Search"
            name="search"
            className="search"
            value={props.input}
            onChange={handleChange}
          />
        </div>
        {props.children}
      </div>
    </PageHeader>
  );
}

export default PageHeaderComponent;
