import React from "react";
import { Col, Image, Layout, Row } from "antd";

const { Content } = Layout;

function LoginLayout(props) {

  return (
    <Layout>
      <Content>
        <Row align="middle" justify="center">
          <Col lg={{span:24}}>
            <Row align="middle" justify="center">
              <Col span={24}>
                {props.children}
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
}

export default LoginLayout;
